<template>
    <div class="StoreMarketingTwo">
        <div class="MarketingTwoHeader">
            <el-button class="deleBtn" @click="deleSelect">删除</el-button>
            <div class="headerRight">
                <span>使用官方数据</span>
                <el-select v-model="category_id" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="selectOfficialData">
                    <el-option
                            v-for="item in classifyOptions"
                            :key="item.id"
                            :label="item.marketing_name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="MarketingTwoTable">
            <el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%;flex: 1; margin-top: 20px" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F2F3FA',height: '60px'}">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="商品主图" prop="image" align="center">
                    <template slot-scope="scope">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;cursor: pointer" onclick="uploadInput.click()" @click="getIndex(scope.$index)">
                            <img v-if="scope.row.image" style="width: 44px;height: 44px;" :src="scope.row.src" alt="">
                            <img v-if="!scope.row.image" style="width: 44px;height: 44px;" src="./../../../assets/images/noimg_banner.png" alt="">
                            <span v-if="!scope.row.image" style="font-size: 12px;color: #bbbbbb">点击上传图片</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品标题" prop="title" align="center" width="320">
                    <template slot-scope="scope">
                        <el-input style="width: 300px;" v-model="scope.row.name"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="商品价格" prop="price" align="center">
                    <template slot-scope="scope">
                        <el-input style="width: 150px;" v-model="scope.row.price" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="商品库存" prop="inventory" align="center">
                    <template slot-scope="scope">
                        <el-input style="width: 150px;" v-model="scope.row.inventory" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #EB6547" @click="deleTable(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button @click="addShop" style="width: 90px;height: 30px;background: #ffffff;color: #1122D8;border-color: #1122D8;line-height: 0;border-radius: 3px;margin-top: 10px">添加商品</el-button>
        </div>
        <input
                ref="modelInput"
                multiple
                type="file"
                id="uploadInput"
                @change="uploadingImg($event)"
                style="display:none"
                accept=".jpg,.png,jpeg"
        />
        <div class="toBtn">
            <el-button class="btn1" @click="toBack">上一步</el-button>
            <el-button class="btn1" @click="toNext">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import { mapActions } from 'vuex';
    export default {
        name: "StoreMarketingTwo",
        data(){
            return {
                category_id:'',
                classifyOptions:[],
                tableData:[],
                tableIndex:'',
                deleVal:[]
            }
        },
        created() {
            let ss = this.$route.query
            this.getShowStoreMarket();
            this.getEditData();

        },
        methods: {
            ...mapActions([
               'setGoodsLists'
            ]),
            getEditData(){
                if(this.$route.query.resData){
                    let resData = JSON.parse(this.$route.query.resData);
                    let goodsList = resData.goods_list;
                    for(let i = 0; i < goodsList.length; i++){
                        let obj = {
                            id: goodsList[i].id,
                            src:goodsList[i].image.src,
                            image:goodsList[i].image.url,
                            name:goodsList[i].name,
                            price:goodsList[i].price,
                            inventory:goodsList[i].inventory
                        };
                        this.tableData.push(obj)
                    }
                }
            },
            // setModel(index){
            //     if(this.tableData[index].price){
            //         this.tableData[index].price = this.tableData[index].price + '.00'
            //     }
            // },
            getShowStoreMarket(){
                let param = {
                    type:'all'
                };
                this.$http.axiosGetBy(this.$api.showStoreMarket, param, res=>{
                    if(res.code === 200){
                        this.classifyOptions = res.data
                    } else {
                        this.$message.warning(res.mag)
                    }
                })
            },
            addShop(){
                // console.log('tableData',this.tableData)
              let obj = {
                  src:'',
                  image:'',
                  name:'',
                  price:'',
                  inventory:''
              };
              this.tableData.push(obj)
            },
            selectOfficialData(val){
                this.$http.axiosGetBy(this.$api.showStoreMarket, {id:val,hidden:1}, res=>{
                    if(res.code === 200){
                        let goodsList = res.data.data[0].goods_list;
                        for(let i = 0; i < goodsList.length; i++){
                            let obj = {
                                src:goodsList[i].image.src,
                                image:goodsList[i].image.url,
                                name:goodsList[i].name,
                                price:goodsList[i].price,
                                inventory:goodsList[i].inventory
                            };
                            this.tableData.push(obj)
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            deleTable(index){
                this.tableData.splice(index,1)
            },
            getIndex(index){
              this.tableIndex = index;
              console.log(this.tableIndex)
            },
            uploadingImg(event){
                
                console.log(event)
                let fileSize = event.target.files[0].size;
                let modelName = event.target.files[0].name;
                let isLimite2M = fileSize / 1024 / 1024 < 2;
                let limiteFileType = ['png','jpg','jpeg'];
                if(!limiteFileType.includes(modelName.split(".")[modelName.split(".").length - 1].toLowerCase())){
                    this.$message.warning('文件格式错误，请重新上传正确文件格式！');
                    return;
                }
                if(!isLimite2M){
                    this.$message.warning('上传图片大小不能超过2M！')
                    return false;
                }
                let formData = new FormData();
                formData.append('image', event.target.files[0])
                this.$http.axiosPost(this.$api.uploadImg, formData, res=>{
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.tableData[this.tableIndex].src = res.data.src;
                        this.tableData[this.tableIndex].image = res.data.url;
                        this.$refs.modelInput.value=""
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toBack(){
                this.$router.go(-1)
            },
            toNext(){
               /* console.log(183,this.tableData)
                console.log(JSON.stringify(this.tableData));

                return*/
                if(this.tableData.length > 1){
                    for(let i = 0; i < this.tableData.length; i++){
                        if(!this.tableData[i].image || !this.tableData[i].name || !this.tableData[i].price || !this.tableData[i].inventory){
                            this.$message.warning("每项内容不能为空！");
                            return;
                        }
                    }
                    // this.tableData.map(item=>{
                    //     if(item.image == '' || item.name == '' || item.price =='' || item.inventory == ''){
                    //
                    //     }
                    // })

                    this.tableData.map(item=>{
                        item.price = parseFloat(item.price).toFixed(2)
                    })
                    let postData = _.map(this.tableData, (item) => {
                        let tmp = {
                            name: item.name,
                            image: item.image,
                            price: this.$common.toFormat2dot(Number(item.price)),
                            inventory: item.inventory
                        }
                        if (item.id) {
                            tmp.id = item.id;
                        }
                        return tmp;
                    })
                    let param = {
                        goods_data:JSON.stringify(postData)
                    };
                    this.$http.axiosPost(this.$api.addMarketGoods, param, res=>{
                        if(res.code === 200){
                            this.setGoodsLists(res.data);
                            /*let list = res.data;*/
                            /*list.map(item=>{
                                delete item.src;
                                delete item.update_time;
                                delete item.create_time;
                            })*/
                            if(this.$route.query.resData){
                                this.$router.push({
                                    path: '/admin/operatedata/storemarketingthree',
                                    query: {
                                        oneForm:this.$route.query.oneForm,
                                        storeFile: this.$route.query.storeFile,
                                        // suitFile: this.$route.query.suitFile,
                                        // discountsFile: this.$route.query.discountsFile,
                                        // goods_data:JSON.stringify(list),
                                        resData:this.$route.query.resData
                                    }
                                })
                            } else {
                                this.$router.push({
                                    path: '/admin/operatedata/storemarketingthree',
                                    query: {
                                        oneForm:this.$route.query.oneForm,
                                        storeFile: this.$route.query.storeFile,
                                        // suitFile: this.$route.query.suitFile,
                                        // discountsFile: this.$route.query.discountsFile,
                                        // goods_data:JSON.stringify(list)
                                    }
                                })
                            }
                        }
                    })
                } else {
                    this.$message.warning('最少添加两个商品！');
                }
            },
            deleSelect(){
                if(this.deleVal.length){
                    for(let i = 0; i<this.deleVal.length; i++){
                        for(let j = 0; j < this.tableData.length; j++){
                            if(this.deleVal[i] == this.tableData[j]){
                                this.tableData.splice(j,1)
                            }
                        }
                    }
                } else {
                    this.$message.warning('请选择要删除的商品！')
                }
            },
            handleSelectionChange(val){
                // console.log(96,val);
                this.deleVal = val;
            },
        }
    }
</script>

<style scoped lang="scss">
    .StoreMarketingTwo{
        .MarketingTwoHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .deleBtn{
                width: 90px;
                height: 30px;
                line-height: 0;
                color: #EB6547;
                background: #ffffff;
                border-color: #EB6547;
            }
        }
        .toBtn{
            display: flex;
            justify-content: center;
            margin-top: 80px;
            .btn1{
                width: 100px;
                height: 30px;
                line-height: 0;
            }
            .btn1:nth-child(1){
                background: #ffffff;
                border-color: #1122D8;
                color: #1122D8;
            }
            .btn1:nth-child(2){
                background: #1122D8;
                border-color: #1122D8;
                color: #ffffff;
            }
        }
    }
</style>